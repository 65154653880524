import React, { useEffect, useState } from 'react';

import { ReactComponent as Export } from '../../../assets/icons/export.svg';
import users from '../../../assets/json/sharing-management.json';
import { SharingInterFace } from '../../../interface/revenue-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAllUsers } from '../../../redux/slices/UsersManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ModalBox from '../../../shared-components/modal-box/ModalBox';

import FilterRevenue from './filter-sharing';

/**
 * User Management page function component
 */
function SharingList() {
  const dispatch = useAppDispatch();
  const { limit, page, totalPages, totalResults, loading } = useAppSelector((state) => state.users);

  const [modalView, setModalView] = useState({
    status: false,
    title: 'Add Account',
    header: true,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Fetch Users list function
   */
  const fetchData = (data: any) => {
    if (!loading) {
      dispatch(fetchAllUsers(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: false,
      content: <div />,
      rightCorner: '',
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: any) => {
    fetchData(value);
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: '',
      header: false,
      content: <FilterRevenue closeModal={closeModal} />,
      rightCorner: '',
    });
  };

  /**
   * User filter clear function
   */
  const filterClearFunction = () => {
    setModalView({
      status: true,
      title: '',
      header: false,
      content: <FilterRevenue closeModal={closeModal} />,
      rightCorner: '',
    });
  };

  const column = [
    {
      label: 'Date',
      accessorKey: 'date' as keyof SharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Time',
      accessorKey: 'time' as keyof SharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Station Id',
      accessorKey: 'stationId' as keyof SharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Transaction Id',
      accessorKey: 'transactionId' as keyof SharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Amount',
      accessorKey: 'amount' as keyof SharingInterFace,
      sortAllow: true,
    },
    {
      label: 'CPO',
      accessorKey: 'cpo' as keyof SharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Facility Owner',
      accessorKey: 'facilityOwner' as keyof SharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Distributor',
      accessorKey: 'distributor' as keyof SharingInterFace,
      sortAllow: true,
    },
    {
      label: 'EMSP',
      accessorKey: 'emsp' as keyof SharingInterFace,
      sortAllow: true,
    },
  ];

  useEffect(() => {
    if (users.length === 0) {
      fetchData({
        limit,
        page,
      });
    }
  }, []);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <CustomTable
        header={column}
        data={users}
        serialNo
        filterFunction={filterFunction}
        filterClearFunction={filterClearFunction}
        sizeChangeButtonRequired
        height={432}
        page={page}
        limit={limit}
        loading={loading}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <MyButton
                label="Export Data"
                buttonType="back"
                onClickFunc={() => {}}
                svgIcon={<Export />}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default SharingList;
