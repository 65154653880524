import React, { useEffect, useState } from 'react';

import { ReactComponent as EditPencil } from '../../../assets/icons/edit-pencil.svg';
import { ReactComponent as Export } from '../../../assets/icons/export.svg';
import { ReactComponent as MultiUsers } from '../../../assets/icons/multi-users.svg';
import roles from '../../../assets/json/management.json';
import { ManagementInterFace } from '../../../interface/revenue-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAllRoles } from '../../../redux/slices/RolesManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ModalBox from '../../../shared-components/modal-box/ModalBox';

import FilterRevenue from './filter-sharing';

interface ModalState {
  status: boolean;
  title: string;
  header: boolean;
  content: JSX.Element;
  rightCorner: string;
}

/**
 * User Management page function component
 */
function ManagementList() {
  const dispatch = useAppDispatch();
  const { limit, page, totalPages, totalResults, loading } = useAppSelector((state) => state.roles);
  const column = [
    {
      label: 'Account Id',
      accessorKey: 'accountId' as keyof ManagementInterFace,
      sortAllow: true,
    },
    {
      label: 'Account Type',
      accessorKey: 'type' as keyof ManagementInterFace,
      sortAllow: true,
    },
    {
      label: 'Percentage',
      accessorKey: 'percentage' as keyof ManagementInterFace,
      sortAllow: true,
    },
  ];
  const [modalView, setModalView] = useState<ModalState>({
    status: false,
    title: '',
    header: true,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Fetch Users list function
   */
  const fetchData = (data: any) => {
    if (!loading) {
      dispatch(fetchAllRoles(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: true,
      content: <div />,
      rightCorner: '',
    });
  };

  /**
   * User details view function
   */
  const viewFunction = () => {
    setModalView({
      status: false,
      title: '',
      header: true,
      content: <div />,
      rightCorner: '',
    });
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: 'Roles Filter',
      header: false,
      content: <FilterRevenue closeModal={closeModal} />,
      rightCorner: '',
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: any) => {
    fetchData(value);
  };

  useEffect(() => {
    if (roles.length === 0) {
      fetchData({
        limit,
        page,
      });
    }
  }, []);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <CustomTable
        header={column}
        data={roles}
        serialNo
        actionButton
        height={432}
        loading={loading}
        page={page}
        limit={limit}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        filterClearFunction={() => {}}
        filterFunction={filterFunction}
        actionButtonList={[
          {
            icon: <EditPencil />,
            function: viewFunction,
            color: 'view-color',
            allow: true,
          },
        ]}
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <MyButton
                label="Export Data"
                buttonType="back"
                onClickFunc={() => {}}
                svgIcon={<Export />}
              />
            </div>
            <div className="table-button-item">
              <div className="user-count">
                <div className="icon">
                  <MultiUsers />
                </div>
                <div className="value">{totalResults} Users</div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default ManagementList;
