import deleteRequest from './base/delete';
import getRequest from './base/get';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the Accounts list with pagination
   */
  fetchConsumers: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/consumer/users`, {
      ...parameters,
    });
  },
  /**
   * Delete the user api call function
   * @param data
   * @returns
   */
  deleteUser: (
    id: String,
  ): Promise<any> => {
    return deleteRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/consumer/users/${id}`);
  },
};
