import L from 'leaflet';
import React, { useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import { ReactComponent as AlertIcon } from '../../../assets/icons/alert-icon.svg';
import AlertStationIcon from '../../../assets/icons/dashboard/alert-station-locator.svg';
import { ReactComponent as ActiveClockIcon } from '../../../assets/icons/dashboard/clock-icon.svg';
import { ReactComponent as ActiveConnectorIcon } from '../../../assets/icons/dashboard/connector-icon.svg';
import { ReactComponent as ActiveDeviceIcon } from '../../../assets/icons/dashboard/device-icon.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/dashboard/right-arrow.svg';
import StationLocator from '../../../assets/icons/dashboard/station-locator.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/dashboard/user-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location-icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import ChargingDevice from '../../../assets/images/login/login-banner.png';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';

interface StationProperties {
  id: string;
  position: [number, number]; // Ensures two elements: [latitude, longitude]
  alert: boolean;
}

/**
 * Map component to station location
 */
function DashboardMap() {
  const [showCards, setShowCards] = useState<boolean>(true);
  const [searchStation, setSearchStation] = useState<string | number>('');

  // To-do -> This data should be populated from API
  const stationLocation: StationProperties[] = [
    {
      id: '1',
      position: [20.5937, 78.9629],
      alert: false,
    },
    {
      id: '2',
      position: [25.5937, 80.9629],
      alert: true,
    },
  ];

  const availableStationIcon = L.icon({
    iconUrl: StationLocator,
    iconSize: [44, 44], // Adjust the icon size as needed
    // iconAnchor: [16, 32], // Adjust anchor point if necessary
    // popupAnchor: [0, -32],
  });

  const alertStationIcon = L.icon({
    iconUrl: AlertStationIcon,
    iconSize: [76, 47], // Adjust the icon size as needed
    // iconAnchor: [16, 32], // Adjust anchor point if necessary
    // popupAnchor: [0, -32],
  });

  return (
    <div className="dashboard-map-wrap" onMouseLeave={() => setShowCards(true)}>
      {/* Absolute search box */}
      <div className="map-search-wrap" role="presentation" onClick={() => setShowCards(false)}>
        <CustomInput
          inputLabel=""
          type="text"
          placeholder="Search"
          name="search"
          value={searchStation}
          onChangeValue={(name: string, value: string | number) => setSearchStation(value)}
          frontSvgIcon={<SearchIcon />}
        />
      </div>
      <div className="dashboard-map-container">
        <MapContainer
          center={{ lat: 20.5937, lng: 78.9629 }}
          zoom={5}
          zoomControl={false}
          style={{ height: '100%', width: '100%' }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {stationLocation.map((station) => (
            <Marker
              key={station.id}
              position={station.position}
              icon={station.alert ? alertStationIcon : availableStationIcon}>
              <Popup closeButton={false}>
                {station.alert ? (
                  <div className="map-station-details-wrap alert-wrap">
                    <div className="map-station-details-container">
                      <span>
                        <AlertIcon />
                      </span>
                      <p>Station Performance Alerts</p>
                    </div>
                  </div>
                ) : (
                  <div className="map-station-active-container">
                    <div className="map-station-details">
                      <div className="details-header">
                        <div className="details-header-image-wrap">
                          <img src={ChargingDevice} alt="" className="details-header-image" />
                        </div>
                        <div className="name-and-status">
                          <h2>Station 1</h2>
                          <div className="status-wrap">
                            <div className="status-point" />
                            <p>Active</p>
                          </div>
                        </div>
                      </div>
                      <div className="address-details">
                        <div className="place-with-icon">
                          <span>India</span>
                          <span>
                            <LocationIcon />
                          </span>
                        </div>
                        <p>45 Saint Martin’s Lane, Charing Cross, WC2N 4HX</p>
                      </div>
                      <div className="user-details">
                        <span>
                          <UserIcon />
                        </span>
                        <span className="count">23</span>
                        <span className="message">Client on station</span>
                      </div>
                    </div>
                    <div className="map-station-center-border" />
                    <div className="map-station-availability-wrap">
                      <div className="availability-status-wrap">
                        <div className="each-availability">
                          <span>
                            <ActiveClockIcon />
                          </span>
                          <p>06:00 AM - 07:00 PM</p>
                        </div>
                        <div className="each-availability">
                          <span>
                            <ActiveDeviceIcon />
                          </span>
                          <p>3 Charging Points</p>
                        </div>
                        <div className="each-availability">
                          <span>
                            <ActiveConnectorIcon />
                          </span>
                          <p>Type 2</p>
                        </div>
                      </div>
                      <div className="view-more-button">
                        <MyButton
                          label="View more"
                          buttonType="submit"
                          svgIcon={<RightArrow />}
                          onClickFunc={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
            </Marker>
          ))}
        </MapContainer>

        {/* Absolute background color */}
        {showCards && <div className="absolute-background" />}
      </div>
    </div>
  );
}

export default DashboardMap;
