import React, { useEffect, useState } from 'react';

import { ReactComponent as Export } from '../../assets/icons/export.svg';
import { PaymentsInterFace } from '../../interface/payment-management.interface';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAllPayments } from '../../redux/slices/PaymentManagementSlice';
import MyButton from '../../shared-components/button/Button';
import CustomTable from '../../shared-components/custom-table/CustomTable';
import ModalBox from '../../shared-components/modal-box/ModalBox';

import FilterPayment from './components/filter-account';

/**
 * Payments Management function Component
 */
function PaymentManagement() {
  const dispatch = useAppDispatch();
  const { payments, limit, page, totalPages, totalResults, loading } = useAppSelector(
    (state) => state.payments,
  );
  const column = [
    {
      label: 'Date',
      accessorKey: 'date' as keyof PaymentsInterFace,
    },
    {
      label: 'User Name',
      accessorKey: 'name' as keyof PaymentsInterFace,
    },
    {
      label: 'Email',
      accessorKey: 'email' as keyof PaymentsInterFace,
    },
    {
      label: 'Type',
      accessorKey: 'type' as keyof PaymentsInterFace,
    },
    {
      label: 'Amount',
      accessorKey: 'amount' as keyof PaymentsInterFace,
    },
    {
      label: 'Transaction Id',
      accessorKey: 'transactionId' as keyof PaymentsInterFace,
    },
    {
      label: 'Payment Status',
      accessorKey: 'paymentStatus' as keyof PaymentsInterFace,
      /**
       * Data custom function
       * @param currentRow
       */
      customData: (currentRow: PaymentsInterFace) =>
        currentRow.paymentStatus ? (
          <div className="text-success">Success</div>
        ) : (
          <div className="text-danger">Failed</div>
        ),
    },
    {
      label: 'Refund Status',
      accessorKey: 'status' as keyof PaymentsInterFace,
    },
  ];

  const [openStatus, setOpenStatus] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    header: false,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Fetch the payment details in redus
   */
  const fetchData = (data: any) => {
    if (!loading) {
      dispatch(fetchAllPayments(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setOpenStatus(false);
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: any) => {
    fetchData(value);
  };

  /**
   * Payment filter view function
   */
  const paymentFilterFunction = () => {
    setOpenStatus(true);
    setModalView({
      title: '',
      header: false,
      content: <FilterPayment closeModal={closeModal} />,
      rightCorner: 'filter-modal',
    });
  };

  useEffect(() => {
    if (payments.length === 0) {
      fetchData({
        limit,
        page,
      });
    }
  }, []);
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setOpenStatus(false)}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <div className="container">
        <div className="header-title">
          <h2>Payments</h2>
        </div>
        <div className="body-container">
          <div className="table-content">
            <CustomTable
              header={column}
              data={payments}
              limit={limit}
              page={page}
              totalPages={totalPages}
              totalResults={totalResults}
              loading={loading}
              serialNo
              pageLimitChange={pageNateLimitChange}
              height={354}
              filterFunction={paymentFilterFunction}
              filterClearFunction={() => {}}
              sizeChangeButtonRequired
              footerLeft={
                <div className="table-bottom-button-list">
                  <div className="table-button-item">
                    <MyButton
                      label="Export Data"
                      buttonType="back"
                      onClickFunc={() => {}}
                      svgIcon={<Export />}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentManagement;
