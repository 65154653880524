/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import { PaymentsInterFace } from '../../interface/payment-management.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import paymentApi from '../../utils/api/payments';

import { setNotificationMessage } from './NotificationManagementSlice';

// Define the type of data expected
interface FetchParameters {
  search?: string;
  page?: number;
  limit?: number;
}

interface PaymentInitialProperties {
  payments: PaymentsInterFace[];
  limit: number;
  page: number;
  totalPages: number;
  totalResults: number;
  loading: boolean;
}

// a thunk to fetch payments
export const fetchAllPayments = createAsyncThunk<PaymentsInterFace[], FetchParameters>(
  'payment-management/fetchAllPayments',
  async (data: FetchParameters, { dispatch }) => {
    const response = await paymentApi
      .fetchPayments(data)
      .then((response1) => response1)
      .catch((error) =>
        dispatch(
          setNotificationMessage({
            type: 'error',
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
            status: true,
          }),
        ),
      );
    return {
      ...response.data,
      results: response.data.results.map((roleData: any) => ({
        ...roleData,
        date: ConvertUTCtoDate(roleData.createdAt),
        id: roleData._id,
      })),
    };
  },
);

interface APIResponseInterface {
  limit: number;
  page: number;
  loading: boolean;
  results: PaymentsInterFace[];
  totalPages: number;
  totalResults: number;
}
// Initial state
const initialState: PaymentInitialProperties = {
  payments: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
};

// Create the slice
export const paymentSlice = createSlice({
  name: 'payment-management',
  initialState,
  reducers: {},
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchAllPayments.pending, (state: PaymentInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllPayments.fulfilled,
        (state: PaymentInitialProperties, action: { payload: APIResponseInterface }) => {
          state.loading = false;
          state.payments = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllPayments.rejected, (state: PaymentInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

export default paymentSlice.reducer;
