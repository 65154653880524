import { ReactComponent as FilterClose } from '../../assets/icons/filter-close.svg';
import { ReactComponent as Filter } from '../../assets/icons/filter.svg';
import { ReactComponent as SizeChangeIcon } from '../../assets/icons/size-change-icon.svg';
import MyButton from '../button/Button';

interface TopHeaderButtonInterFace {
  filterFunction: () => void;
  filterClearFunction: () => void;
  sizeChangeFunction?: () => void;
  filterAllow?: boolean;
  sizeChangeButtonRequired?: boolean;
  rightTopContent?: JSX.Element;
}

/**
 * Top header buttons function component
 */
function TopHeaderButtons({
  filterFunction,
  filterClearFunction,
  sizeChangeFunction = () => {},
  filterAllow,
  sizeChangeButtonRequired,
  rightTopContent,
}: TopHeaderButtonInterFace) {
  return (
    <div className="top-header-warp">
      <div className="top-header-buttons">
        <div className="top-header-left">
          {filterAllow && (
            <div className="button-group">
              <div className="button-item">
                <MyButton
                  label=""
                  buttonType="primary-outline"
                  onClickFunc={filterFunction}
                  svgIcon={<Filter />}
                />
              </div>
              <div className="button-item">
                <MyButton
                  label="Clear Filter"
                  buttonType="primary-outline"
                  onClickFunc={filterClearFunction}
                  svgBackIcon={<FilterClose />}
                />
              </div>
            </div>
          )}
        </div>
        <div className="top-header-right">
          {sizeChangeButtonRequired && (
            <MyButton
              label=""
              buttonType="primary-outline"
              onClickFunc={sizeChangeFunction}
              svgIcon={<SizeChangeIcon />}
            />
          )}
          {rightTopContent && rightTopContent}
        </div>
      </div>
    </div>
  );
}

TopHeaderButtons.defaultProps = {
  /**
   * Size change function
   */
  sizeChangeFunction: () => {},
  sizeChangeButtonRequired: false,
  filterAllow: true,
  rightTopContent: null,
};

export default TopHeaderButtons;
