import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ChargingPoint } from '../../assets/icons/dashboard/charging-point.svg';
import { ReactComponent as MapArrowIcon } from '../../assets/icons/dashboard/map-arrow-icon.svg';
import { ReactComponent as CardEnergyIcon } from '../../assets/icons/dashboard/map-energy-icon.svg';
import { ReactComponent as CardRevenueIcon } from '../../assets/icons/dashboard/map-revenue-icon.svg';
import { ReactComponent as CardMultiUserIcon } from '../../assets/icons/dashboard/map-user-icon.svg';
import { ReactComponent as Export } from '../../assets/icons/export.svg';
import MyButton from '../../shared-components/button/Button';
import CustomToggleButton from '../../shared-components/custom-toggle-button/CustomToggleButton';

import DashboardMap from './components/DashboardMap';
import ReportGraphChart from './components/report-graph';

interface GraphDataInterface {
  label: string;
  data: (string | number)[];
  borderColor: string;
  bgColor: string;
}

/**
 * Dashboard function Component
 */
function Dashboard() {
  const graphNames: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const graphData: GraphDataInterface[] = [
    {
      label: 'Electricity',
      data: [30000, 32000, 31000, 54000, 33000, 34000, 36000, 28000, 7000, 39000, 40000, 42000],
      borderColor: '#0399e0',
      bgColor: 'rgba(104, 192, 228, 1)',
    },
    {
      label: 'Money',
      data: [3000, 32000, 35000, 34000, 37000, 44000, 36000, 38000, 37000, 38000, 45000, 50000],
      borderColor: '#F79009',
      bgColor: 'rgba(297 189, 110, 1)',
    },
  ];
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  /**
   * Function  to handle navigation
   * @param url
   */
  const handleIconClick = (url: string) => {
    navigate(url);
  };

  const mapCardData = [
    {
      title: 'Total Electricity Consumed',
      dataToShow: '34,000',
      unit: '(KWh)',
      activeDevices: 50,
      onClickFunc: handleIconClick,
      icon: <CardEnergyIcon />,
      url: '../reports',
    },
    {
      title: 'Total Revenue',
      dataToShow: '34,000',
      unit: '(SAR)',
      activeDevices: 50,
      onClickFunc: handleIconClick,
      icon: <CardRevenueIcon />,
      url: '../revenue/sharing',
    },
    {
      title: 'Total Users',
      dataToShow: '3000',
      unit: '',
      activeDevices: 50,
      onClickFunc: handleIconClick,
      icon: <CardMultiUserIcon />,
      url: '../user',
    },
  ];
  return (
    <div className="dashboard-wrap">
      <div className="container">
        <div className="header-title">
          <h2>Dashboard</h2>
        </div>
        <DashboardMap />
        <div className="cards-wrap">
          {mapCardData?.map((cardData, index) => (
            <div
              className="each-card-container"
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}>
              <h3>{cardData.title}</h3>
              <h1>
                {cardData.dataToShow} <span>{cardData.unit}</span>
              </h1>
              <div className="device-count-container">
                <div className="footer-card">
                  <span>
                    <ChargingPoint />
                  </span>
                  <p>
                    {cardData.activeDevices}
                    <span>Active Devices</span>
                  </p>
                </div>
                <div className="footer-card">
                  <div className="tag-success-label">+07%</div>
                  <span>Than last month</span>
                </div>
              </div>
              <div className="card-arrow-wrap">
                <div className={`card-arrow-container ${hoverIndex === index && 'hovered'}`}>
                  <span className="in-active-icon">{cardData.icon}</span>
                  <span
                    className="active-icon"
                    onClick={() => navigate(cardData.url)}
                    role="presentation">
                    <MapArrowIcon />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="reports-graph-content">
          <div className="reports-graph-header-container">
            <div className="reports-title">Application Status</div>
            <div className="reports-graph-header">
              <div className="reports-toggle-btn">
                <CustomToggleButton
                  labels={['12 Months', 'Last Month', '7 Days', '24 hours']}
                  onToggle={() => {}}
                  defaultActiveIndex={0}
                />
              </div>
              <div className="reports-export-btn">
                <MyButton
                  label="Export Data"
                  buttonType="back"
                  disableHoverEffect
                  onClickFunc={() => {}}
                  svgIcon={<Export />}
                />
              </div>
            </div>
          </div>
          <div className="reports-graph">
            <ReportGraphChart labels={graphNames} data={graphData} currency="SAR" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
