import getRequest from './base/get';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the Accounts list with pagination
   */
  fetchPayments: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query, limit: query?.limit || 5 };
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/user-account/list-accounts`, { ...parameters });
  },
};
