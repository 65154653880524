import React, { useEffect, useState } from 'react';

import { RoleInterFace } from '../../../interface/role-management.interface';
import { FilterUserInterFace } from '../../../interface/users-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAllListRoles } from '../../../redux/slices/RolesManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomDateRange from '../../../shared-components/custom-date-range/CustomDateRange';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
// import roleApi from '../../../utils/api/roles';
import SelectFiled from '../../../shared-components/select-field/SelectField';

interface AddRoleInterface {
  closeModal: () => void;
  handleSubmitFilter: (filterData: FilterUserInterFace) => void;
  oldValue: FilterUserInterFace;
}
/**
 * Role Add page function component
 */
function FilterUser({ closeModal, handleSubmitFilter, oldValue }: AddRoleInterface) {
  const dispatch = useAppDispatch();
  const { rolesList } = useAppSelector((state) => state.roles);
  const [filterDetails, setFilterDetails] = useState<FilterUserInterFace>(oldValue || {});

  /**
   * Get data function
   */
  const fetchData = () => {
    dispatch(fetchAllListRoles());
  }

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setFilterDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    handleSubmitFilter(filterDetails);
  };

  useEffect(() => {
    if (rolesList.length === 0) {
      fetchData();
    }
  }, []);

  return (
    <div className="filter-user-warp">
      <div className="form-body">
        <div className="form-field">
          <SelectFiled
            name="role"
            inputLabel="User Role"
            onChangeValue={handleInputChange}
            placeholder="Select Role"
            options={rolesList.map((role: RoleInterFace) => ({
              label: role.name,
              value: role.id,
            }))}
            selectedValue={filterDetails.role || ''}
          />
        </div>
        <div className="form-field">
          <CustomDateRange
            name1="fromDate"
            name2="toDate"
            inputLabel="Date"
            valueRequired
            value1={filterDetails.fromDate || ''}
            value2={filterDetails.toDate || ''}
            onChangeValue={handleInputChange}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="User Name"
            type="text"
            placeholder="Enter the name"
            value={filterDetails.name || ''}
            onChangeValue={handleInputChange}
          />
        </div>
        <div className="form-field">
          <SelectFiled
            name="status"
            inputLabel="Status"
            placeholder="Select Status"
            onChangeValue={handleInputChange}
            options={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inactive',
              },
            ]}
            selectedValue={filterDetails.status || ''}
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton label="Submit" buttonType="submit" onClickFunc={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterUser;
