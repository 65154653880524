import { StationParameters, StationStatusChangeParameters } from '../../interface/charging-station.interface';

import deleteRequest from './base/delete';
import getRequest from './base/get';
import patchRequest from './base/patch';
import postRequest from './base/post';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the charging station list with pagination
   */
  fetchChargingStations: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/charging-station`, {
      ...parameters,
    });
  },
  /**
   * Create the station api call function
   * @param data
   * @returns
   */
  createChargingStation: (
    data: StationParameters = {
      name: '',
      email: '',
      role: '',
      password: '',
    },
  ): Promise<any> => {
    const parameters = { ...data };
    return postRequest(`${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/users`, {
      ...parameters,
    });
  },
  /**
   * Update the station api call function
   * @param data
   * @returns
   */
  editChargingStation: (
    id: string,
    data: StationParameters = {
      name: '',
      email: '',
      role: '',
    },
  ): Promise<any> => {
    const parameters = { ...data };
    return patchRequest(`${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/users/${id}`, {
      ...parameters,
    });
  },
  /**
   * Delete the station api call function
   * @param data
   * @returns
   */
  deleteChargingStation: (
    id: String,
  ): Promise<any> => {
    return deleteRequest(`${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/users/${id}`);
  },
  /**
   * Delete the station api call function
   * @param data
   * @returns
   */
  statusChangeChargingStation: (
    id: string,
    data: StationStatusChangeParameters,
  ): Promise<any> => {
    const parameters = { ...data };
    return patchRequest(`${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/charging-station/${id}`, {
      ...parameters,
    });
  },
};
