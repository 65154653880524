import React, { useState } from 'react';

import { ReactComponent as CaffeeIcon } from '../../../assets/icons/caffee-color-icon.svg';
import { ReactComponent as CarWashIcon } from '../../../assets/icons/car-wash-color-icon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as CloseCircleIcon } from '../../../assets/icons/close-circle.svg';
import { ReactComponent as GasItemIcon } from '../../../assets/icons/gas-item-color-icon.svg';
import { ReactComponent as LoungeActive } from '../../../assets/icons/lounge-active.svg';
import { ReactComponent as RelaxingIcon } from '../../../assets/icons/relasing-color-icon.svg';
import { ReactComponent as RestaurantActive } from '../../../assets/icons/restaurant-active.svg';
import { ReactComponent as RestroomIcon } from '../../../assets/icons/restroom-color-icon.svg';
import { ReactComponent as SettingIcon } from '../../../assets/icons/setting-color-icon.svg';
import { ReactComponent as ShopIcon } from '../../../assets/icons/shopping-color-icon.svg';
import { ReactComponent as WifiIcon } from '../../../assets/icons/wifi-color-icon.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import { ChargingStationInterFace } from '../../../interface/charging-station.interface';
import { useAppDispatch } from '../../../redux/hooks';
import { fetchAllStation } from '../../../redux/slices/ApprovedStationManagementSlice';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import { fetchAllUnapprovedStation } from '../../../redux/slices/UnapprovedStationManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomFileInput from '../../../shared-components/custom-file-input/CustomFileInput';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import CustomMediaUpload from '../../../shared-components/custom-media-upload/CustomMediaUpload';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import SuccessNotification from '../../../shared-components/success-notification/SuccessNotificaion';
import stationApi from '../../../utils/api/charging-station';

import RejectStation from './reject-station';

interface AmenitiesDetailsProperties {
  name: string;
  key: string;
  svgIconActive: JSX.Element;
}

/**
 * View charging station details function component
 */
function ViewChargingStation({
  details,
  closeModal,
}: {
  details: ChargingStationInterFace;
  closeModal: () => void;
}) {
  const amenitiesDetails: AmenitiesDetailsProperties[] = [
    {
      name: 'Gas Station',
      key: 'Gas Station',
      svgIconActive: <GasItemIcon />,
    },
    {
      name: 'Free Wifi',
      key: 'Free Wifi',
      svgIconActive: <WifiIcon />,
    },
    {
      name: 'Restroom',
      key: 'Restroom',
      svgIconActive: <RestroomIcon />,
    },
    {
      name: 'Lounge',
      key: 'Lounge',
      svgIconActive: <LoungeActive />,
    },
    {
      name: 'Cafe',
      key: 'Cafe',
      svgIconActive: <CaffeeIcon />,
    },
    {
      name: 'Shopping',
      key: 'Shopping',
      svgIconActive: <ShopIcon />,
    },
    {
      name: 'Restaurant',
      key: 'Restaurant',
      svgIconActive: <RestaurantActive />,
    },
    {
      name: 'Relaxing Area',
      key: 'Relaxing Area',
      svgIconActive: <RelaxingIcon />,
    },
    {
      name: 'Work Shop',
      key: 'Work Shop',
      svgIconActive: <SettingIcon />,
    },
    {
      name: 'Car Wash',
      key: 'Car Wash',
      svgIconActive: <CarWashIcon />,
    },
  ];

  const dispatch = useAppDispatch();
  const [modalView, setModalView] = useState({
    content: <div />,
    status: false,
    notification: false,
  });

  /**
   * Close the confirm modal box function
   */
  const closeConfirmModal = () => {
    setModalView({
      content: <div />,
      status: false,
      notification: false,
    });
  };

  /**
   * Station the reject use reason
   * @param data
   */
  const stationReject = (data: ChargingStationInterFace) => {
    stationApi
      .statusChangeChargingStation(data.id, {
        submissionStatus: 'rejected',
        reason: data.reason,
      })
      .then(() => {
        closeConfirmModal();
        closeModal();
        dispatch(
          fetchAllUnapprovedStation({
            page: DefaultApiParameters.page,
            limit: DefaultApiParameters.limit,
            submissionStatus: 'pending',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * Reject the station function
   */
  const rejectStation = () => {
    setModalView({
      content: (
        <RejectStation
          conformSubmit={stationReject}
          closeModal={closeConfirmModal}
          stationDetails={details}
        />
      ),
      status: true,
      notification: false,
    });
  };

  /**
   * Approved the station function
   */
  const approvedStatus = () => {
    stationApi
      .statusChangeChargingStation(details.id, {
        submissionStatus: 'approved',
      })
      .then(() => {
        setModalView({
          content: (
            <SuccessNotification
              title="Station Approved Successfully..."
              description=""
              notificationCloseTimer={3000}
              onNotificationClose={() => {
                closeConfirmModal();
                closeModal();
                dispatch(
                  fetchAllUnapprovedStation({
                    page: DefaultApiParameters.page,
                    limit: DefaultApiParameters.limit,
                    submissionStatus: 'pending',
                  }),
                );
                dispatch(
                  fetchAllStation({
                    page: DefaultApiParameters.page,
                    limit: DefaultApiParameters.limit,
                    submissionStatus: 'approved',
                  }),
                );
              }}
            />
          ),
          status: true,
          notification: true,
        });
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };
  return (
    <div className="view-charging-station-warp">
      <ModalBox
        title=""
        content={modalView.content}
        closeModal={closeConfirmModal}
        open={modalView.status}
        header={false}
        rightCorner=""
        notification={modalView.notification}
      />
      <div className="facility-details">
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="Station Name"
            type="text"
            readOnly
            placeholder="Enter Facility Name"
            value={details.name}
          />
        </div>
        <div className="form-field">
          <CustomFileInput
            inputLabel="Land Ownership Document"
            acceptedFileType="image/*, application/pdf"
            placeholder="Upload pdf, jpeg"
            name="landOwnerShipDocument"
            existingFiles={details.landOwnerShipDocument}
            onChangeValue={() => {}}
            readOnly
            valueRequired
            selectedFiles={null}
          />
        </div>
        <div className="form-field">
          <CustomFileInput
            inputLabel="EB-Reports Of Past 6 Months"
            acceptedFileType="image/*, application/pdf"
            placeholder="Upload pdf, jpeg"
            name="ebReport"
            existingFiles={details.ebReport}
            onChangeValue={() => {}}
            readOnly
            valueRequired
            selectedFiles={null}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="electricity"
            inputLabel="Electricity Contact Type"
            type="text"
            readOnly
            placeholder="Enter Electricity Contact Type"
            value={details.electricityContractType}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="time"
            inputLabel="Operating Hours"
            readOnly
            type="text"
            placeholder="Enter Operating Hours"
            value={`${details.opensAt} - ${details.closesAt}`}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="address"
            inputLabel="Address"
            type="text"
            readOnly
            placeholder="Enter Address"
            value={details.address}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="lat_lang"
            inputLabel="Latitude & Longitude"
            type="text"
            readOnly
            placeholder="Mark Latitude & Longitude"
            value={details.latLong}
          />
        </div>
        <div className="form-field">
          <CustomMediaUpload
            inputLabel="Upload Media"
            acceptedFileType="image/* video/*"
            placeholder="Upload jpeg, png, mp4, avi"
            name="medias"
            existingFiles={details.medias}
            onChangeValue={() => {}}
            readOnly
            valueRequired
            selectedFiles={null}
          />
        </div>
        {details.amenities.length > 0 && (
          <div className="form-amenities-icons">
            <div className="title-label">Amenities</div>
            <div className="icon-list">
              {amenitiesDetails
                .filter((icon) => details.amenities.includes(icon.key))
                .map((icon) => (
                  <div className="icon-item">
                    <div className="icon">{icon.svgIconActive}</div>
                    <div className="title">{icon.name}</div>
                  </div>
                ))}
            </div>
          </div>
        )}

        {details.submission.status === 'pending' && (
          <div className="table-bottom-button-list form-bottom-button">
            <div className="table-button-item">
              <MyButton
                label="Reject"
                buttonType="danger-outline"
                onClickFunc={rejectStation}
                svgIcon={<CloseCircleIcon />}
              />
            </div>
            <div className="table-button-item">
              <MyButton
                label="Approve"
                buttonType="submit"
                onClickFunc={approvedStatus}
                svgIcon={<CheckCircleIcon />}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewChargingStation;
