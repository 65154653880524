import React, { useEffect, useState } from 'react';

import { ReactComponent as Export } from '../../assets/icons/export.svg';
import { ReactComponent as GasIcon } from '../../assets/icons/gas-icon.svg';
import { ReactComponent as Location } from '../../assets/icons/location.svg';
import { ReportFilterInterFace, ReportsInterFace, RequestReportInterFace } from '../../interface/report-management.interface';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAllReports, setReportFilterData } from '../../redux/slices/ReportManagementSlice';
import MyButton from '../../shared-components/button/Button';
import CustomTable from '../../shared-components/custom-table/CustomTable';
import ModalBox from '../../shared-components/modal-box/ModalBox';

import ChartSection from './components/ChartSection';
import FilterAccount from './components/filter-report';

/**
 * Account Management function Component
 */
function ReportManagement() {
  const dispatch = useAppDispatch();
  const { reports, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.reports,
  );
  const powerData = [
    { day: 'Mon', value: 25 },
    { day: 'Tue', value: 38 },
    { day: 'Wed', value: 80 },
    { day: 'Thu', value: 42 },
    { day: 'Fri', value: 50 },
    { day: 'Sat', value: 68 },
    { day: 'Sun', value: 70 },
  ];

  const revenueData = [
    { day: 'Mon', value: 25 },
    { day: 'Tue', value: 38 },
    { day: 'Wed', value: 80 },
    { day: 'Thu', value: 42 },
    { day: 'Fri', value: 60 },
    { day: 'Sat', value: 58 },
    { day: 'Sun', value: 95 },
  ];
  const column = [
    {
      label: 'Date',
      accessorKey: 'createdAt' as keyof ReportsInterFace,
      sortAllow: true,
    },
    {
      label: 'Station Name',
      accessorKey: 'stationName' as keyof ReportsInterFace,
      sortAllow: true,
    },
    {
      label: 'Company Name',
      accessorKey: 'companyName' as keyof ReportsInterFace,
      sortAllow: true,
    },
    {
      label: 'Power Consumer',
      accessorKey: 'powerConsumer' as keyof ReportsInterFace,
      sortAllow: true,
    },
    {
      label: 'Location',
      accessorKey: 'location' as keyof ReportsInterFace,
      sortAllow: true,
      /**
       * Data custom function
       * @param currentRow
       */
      customData: (currentRow: ReportsInterFace) => (
        <div className="tag-default-label">
          <Location /> <span>{currentRow.location}</span>
        </div>
      ),
    },
    {
      label: 'Revenue',
      accessorKey: 'Revenue' as keyof ReportsInterFace,
      sortAllow: true,
    },
  ];

  const [openStatus, setOpenStatus] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    header: false,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Fetch the account details in redus
   */
  const fetchData = (data: RequestReportInterFace) => {
    if (!loading) {
      dispatch(fetchAllReports(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setOpenStatus(false);
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: RequestReportInterFace) => {
    dispatch(setReportFilterData({ ...filter, ...value }));
    fetchData({ ...filter, ...value });
  };
  /**
   * Handle filter clear function
   */
  const handleSubmitFunction = (filterData: ReportFilterInterFace) => {
    dispatch(setReportFilterData({ ...filterData }));
    fetchData({
      ...filterData,
      limit,
      page,
    });
  };

  /**
   * Account filter view function
   */
  const reportFilterFunction = () => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: false,
      content: <FilterAccount closeModal={closeModal} handleSubmitFunction={handleSubmitFunction} oldFilterValue={filter} />,
      rightCorner: 'filter-modal',
    });
  };
  /**
   * Handle filter clear function
   */
  const handleClearFilterFunction = () => {
    dispatch(setReportFilterData({}));
    fetchData({
      limit,
      page,
    });
  };

  useEffect(() => {
    if (reports.length === 0) {
      fetchData({
        ...filter,
        limit,
        page,
      });
    }
  }, []);
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setOpenStatus(false)}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <div className="container">
        <div className="header-title">
          <h2>ٌReports</h2>
        </div>
        <div className="body-container">
          <div className="charts-container">
            <ChartSection
              title="Total Power Consumed"
              subtitle="More from"
              submenuTitle="last month"
              value="235 Kwh"
              percentage={10}
              data={powerData}
            />
            <span className="dotted-line">
              <GasIcon /> 50 Device
            </span>
            <ChartSection
              title="Total Revenue"
              subtitle="More from"
              submenuTitle="last month"
              value="34000 (SAR)"
              percentage={10}
              data={revenueData}
            />
          </div>
          <div className="table-content">
            <CustomTable
              header={column}
              data={reports}
              limit={limit}
              page={page}
              totalPages={totalPages}
              totalResults={totalResults}
              loading={loading}
              serialNo
              pageLimitChange={pageNateLimitChange}
              height={619}
              filter={filter}
              filterFunction={reportFilterFunction}
              filterClearFunction={handleClearFilterFunction}
              sizeChangeButtonRequired
              footerLeft={
                <div className="table-bottom-button-list">
                  <div className="table-button-item">
                    <MyButton
                      label="Export Data"
                      buttonType="back"
                      onClickFunc={() => {}}
                      svgIcon={<Export />}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportManagement;
