import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as DownArrow } from '../../assets/icons/down-arrow.svg';

type InputType = 'text' | 'number' | 'email' | 'password';
interface CustomInputProperties {
  inputLabel?: string;
  type: InputType;
  placeholder: string;
  value: string | number;
  value1?: string | number;
  name: string;
  onChangeValue?: (name: string, value: string | number) => void;
  valueRequired?: boolean;
  readOnly?: boolean;
  error?: boolean;
  errorMessage?: string;
}

/**
 * Custom Input box function component
 */
function CustomPhoneInput({
  inputLabel,
  type,
  name,
  placeholder,
  value,
  value1,
  onChangeValue = () => {},
  valueRequired,
  readOnly,
  error,
  errorMessage,
}: CustomInputProperties) {
  const listItems = ['1', '91', '966'];
  const reference = useRef<HTMLDivElement>(null);
  const [list, setList] = useState<true | false>(false);
  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  /**
   * Input box change handle function
   */
  const handleValueChange = (changeValue: string | number) => {
    onChangeValue(name, changeValue);
  };

  /**
   * Open the popup function
   * @param event
   */
  const openPop = (event: React.MouseEvent): void => {
    setList(true);
    setPosition({
      top: event.clientY,
      left: event.clientX,
    });
  };
  /**
   * Handle the close th drop down function
   * @param event
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      setList(false);
    }
  };
  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="custom-input-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className={`custom-input-container ${error && 'error'}`}>
        <div className="frond-text">
          +{value1 || '1'}
          <span onClick={openPop} role="presentation">
            <DownArrow />
          </span>
        </div>
        {list &&
          ReactDOM.createPortal(
            <div
              className="country-code-list"
              ref={reference}
              style={{
                top: `${position.top}px`,
                left: `${position.left - 15}px`,
              }}>
              {listItems.map((item) => (
                <div
                  className="list-item"
                  onClick={() => {
                    onChangeValue('countryCode', item);
                    setList(false);
                  }}
                  role="presentation">
                  {item}
                </div>
              ))}
            </div>,
            document.body,
          )}
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(event) => handleValueChange(event.target.value)}
          required={valueRequired}
          readOnly={readOnly}
        />
      </div>
      {error && <p className="text-danger">{errorMessage}</p>}
    </div>
  );
}

CustomPhoneInput.defaultProps = {
  inputLabel: '',
  valueRequired: false,
  readOnly: false,
  error: false,
  errorMessage: '',
  /**
   * Input change function
   */
  onChangeValue: () => {},
  value1: '91',
};

export default CustomPhoneInput;
