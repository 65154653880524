import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as ActiveManagementIcon } from '../../assets/icons/active-management.svg';
import { ReactComponent as ActiveSharingIcon } from '../../assets/icons/active-sharing.svg';
import { ReactComponent as ManagementIcon } from '../../assets/icons/management.svg';
import { ReactComponent as SharingIcon } from '../../assets/icons/sharing.svg';
import TabMenus from '../../shared-components/tab-menus/TabMenus';

import ManagementList from './components/management-list';
import SharingList from './components/sharing-list';

/**
 * User Management page function component
 */
function RevenueManagement() {
  const { page } = useParams();
  const [openTab, setOpenTab] = useState(page === 'sharing' ? 0 : 1);

  /**
   * Change the page function
   * @param data 
   */
  const changePage = (data: number) => {
    setOpenTab(data);
    window.history.pushState(
      {},
      '',
      `${data === 0 ? 'sharing' : 'management'}`
    );
  }

  return (
    <div className="revenue-body" style={{ width: '100%', height: '100%' }}>
      <div className="container">
        <div className="header-title">
          <h2>Revenue</h2>
        </div>
        <div className="body-container">
          <div className="card-table-tab">
            <TabMenus
              openTab={openTab}
              setOpenTab={changePage}
              menus={[
                {
                  name: 'Sharing',
                  icon: <SharingIcon />,
                  activeIcon: <ActiveSharingIcon />,
                },
                {
                  name: 'Management',
                  icon: <ManagementIcon />,
                  activeIcon: <ActiveManagementIcon />,
                },
              ]}
            />
          </div>
          <div className="table-content">
            {openTab === 0 && <SharingList />}
            {openTab === 1 && <ManagementList />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RevenueManagement;
