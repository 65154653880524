/**
 * Change the date format YYYY-MM-DD function
 * @param value
 * @returns
 */
export const changeDateFormat = (value: string) => {
  if (value === '' || value === undefined) return '';
  const date = new Date(value);
  // const dateMDY = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(date);
  const dateMDY = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  return dateMDY;
};


/**
 * Remove the empty keys in object function
 * @param inputObj
 * @returns
 */
export const removeEmptyKeys = (inputObject: any) => {
  return Object.fromEntries(
    Object.entries(inputObject).filter(
      (value) => value[1] !== '' && value[1] !== null && value[1] !== undefined,
    ),
  );
};

/**
 * Split array
 */
export const splitArrayIntoChunks = (array: number[], chunkSize: number) => {
  const result = [];
  for (let index = 0; index < array.length; index += chunkSize) {
    result.push(array.slice(index, index + chunkSize));
  }
  return result;
};
