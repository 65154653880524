/* eslint-disable no-param-reassign */
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  ChargingStationAPIResponse,
  ChargingStationInitialProperties,
  ChargingStationInterFace,
  FilterChargingStationInterFace,
  RequestChargingStationInterface,
} from '../../interface/charging-station.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import ConvertUTCtoHHMM from '../../shared-functions/ConvertUTCtoHHMM';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import FormatUtcString from '../../shared-functions/FormatUtcString';
import stationApi from '../../utils/api/charging-station';

import { setNotificationMessage } from './NotificationManagementSlice';

// a thunk to fetch users
export const fetchAllRejectedStation = createAsyncThunk<
  ChargingStationAPIResponse,
  RequestChargingStationInterface
>(
  'rejected-station-management/fetchAllRejectedStation',
  async (data: RequestChargingStationInterface, { dispatch }) => {
    const response = await stationApi
      .fetchChargingStations(removeEmptyKeys(data))
      .then((response1) => response1)
      .catch((error) =>
        dispatch(
          setNotificationMessage({
            type: 'error',
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
            status: true,
          }),
        ),
      );
    return {
      ...response.data,
      results: response.data.results.map((stationData: ChargingStationInterFace) => ({
        ...stationData,
        date: ConvertUTCtoDate(stationData.createdAt),
        latLong: `${stationData.latitude}, ${stationData.longitude}`,
        opensAt: FormatUtcString(ConvertUTCtoHHMM(stationData?.opensAt)),
        closesAt: FormatUtcString(ConvertUTCtoHHMM(stationData?.closesAt)),
        id: stationData._id,
      })),
    };
  },
);

// Initial state
const initialState: ChargingStationInitialProperties = {
  stations: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const rejectedStationSlice = createSlice({
  name: 'rejected-station-management',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setRejectStationFilterData: (
      state: ChargingStationInitialProperties,
      action: PayloadAction<FilterChargingStationInterFace>,
    ) => {
      state.filter = action.payload;
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<ChargingStationInitialProperties>) => {
    builder
      .addCase(fetchAllRejectedStation.pending, (state: ChargingStationInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllRejectedStation.fulfilled,
        (
          state: ChargingStationInitialProperties,
          action: PayloadAction<ChargingStationAPIResponse>,
        ) => {
          state.loading = false;
          state.stations = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllRejectedStation.rejected, (state: ChargingStationInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
export const { setRejectStationFilterData } = rejectedStationSlice.actions;
// Export the reducer
export default rejectedStationSlice.reducer;
