import deleteRequest from './base/delete';
import getRequest from './base/get';
import patchRequest from './base/patch';
import postRequest from './base/post';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the Accounts list with pagination
   */
  fetchAccounts: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query, limit: query?.limit || 5 };
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/facility-account/list-accounts`, { ...parameters });
  },
  /**
   * Fetch single Accounts list with pagination
   */
  fetchOneAccounts: (query: string): Promise<any> => {
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/facility-user/${query}`, {});
  },
  /**
   * create the Accounts list with pagination
   */
  createAccounts: (data: any): Promise<any> => {
    const parameters = { ...data };
    return postRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/facility-account/create-fo`, parameters, true);
  },
  /**
   * Edit the account function
   * @param id 
   * @param data 
   * @returns 
   */
  editAccounts: (id: string, data: any): Promise<any> => {
    const parameters = { ...data };
    return patchRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/facility-account/edit-account/${id}`, { ...parameters }, true);
  },
  /**
   * Approved the account function
   * @param id 
   * @param data 
   * @returns 
   */
  approvedAccounts: (id: string, data: any): Promise<any> => {
    const parameters = { ...data };
    return patchRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/facility-account/verify-account/${id}`, { ...parameters });
  },
  /**
   * Delete the account api call function
   * @param data
   * @returns
   */
  deleteAccount: (
    id: String,
  ): Promise<any> => {
    return deleteRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/facility-account/delete-account/${id}`);
  },
};
