import { configureStore } from '@reduxjs/toolkit';

import AccountManagementReducer from './slices/AccountManagementSlice';
import ApprovedStationManagementReducer from './slices/ApprovedStationManagementSlice';
import EndConsumerManagementReducer from './slices/EndConsumerManagementSlice';
import NotificationMessageReducer from './slices/NotificationManagementSlice';
import PaymentManagementReducer from './slices/PaymentManagementSlice';
import RejectedStationManagementReducer from './slices/RejectedStationManagementSlice';
import ReportManagementReducer from './slices/ReportManagementSlice';
import RoleManagementReducer from './slices/RolesManagementSlice';
import UnapprovedStationManagementReducer from './slices/UnapprovedStationManagementSlice';
import UserManagementReducer from './slices/UsersManagementSlice';

// Store configure with the reducer
export const store = configureStore({
  reducer: {
    accounts: AccountManagementReducer,
    endConsumer: EndConsumerManagementReducer,
    notification: NotificationMessageReducer,
    payments: PaymentManagementReducer,
    users: UserManagementReducer,
    roles: RoleManagementReducer,
    approvedStation: ApprovedStationManagementReducer,
    unapprovedStation: UnapprovedStationManagementReducer,
    rejectedStation: RejectedStationManagementReducer,
    reports: ReportManagementReducer,
  },
});

// RootState and AppDispatch types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
