import React, { useState } from 'react';

import { ReactComponent as MoreIcon } from '../../assets/icons/more-icon.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/sort-icon.svg';
import NoDataFound from '../../assets/images/no-data-found.png';
import DefaultApiParameters from '../../enum/DefaultApiParameters';
import { ApiRequest } from '../../interface/common.interface';
import BatteryLoader from '../battery-loader/BatteryLoader';
import DropDownMenu from '../drop-down/DropDownMenu';
import Pagination from '../pagination/Pagination';
import TopHeaderButtons from '../top-header-buttons/TopHeaderButtons';

type RowWithId = { id: string }; // Ensure id is either string or number
type RowDataTypes = string | number | null;

// Define ColumnHeaderProps to be generic and flexible for any data row structure
type ColumnHeaderProperties<T> = {
  columnWidth?: number;
  label: string;
  position?: string;
  customLabel?: JSX.Element;
  accessorKey: keyof T; // Tied to the keys of the data row (T)
  customData?: (currentRow: T, index: number) => JSX.Element | null; // Custom rendering function for the data
  sortAllow?: boolean;
};

interface CustomTableProperties<T extends RowWithId> {
  header: ColumnHeaderProperties<T>[]; // Header configuration for the table
  data: T[]; // Array of data representing rows in the table
  footerLeft?: JSX.Element;
  rightTopContent?: JSX.Element;
  height?: number;
  page?: number;
  limit?: number;
  totalPages?: number;
  totalResults?: number;
  filter?: any;
  serialNo?: boolean;
  loading?: boolean;
  actionButtonList?: {
    color: String;
    function: (currentData: String, event?: React.MouseEvent) => void;
    icon: JSX.Element;
    allow: boolean;
  }[];
  actionDropDownList?: {
    function: (value: String) => void;
    content: JSX.Element;
    allow: boolean;
  }[];
  actionButton?: boolean;
  actionDropdown?: boolean;
  filterFunction?: () => void;
  filterClearFunction?: () => void;
  pageLimitChange?: (value: ApiRequest) => void;
  sizeChangeButtonRequired?: boolean;
  filterAllow?: boolean;
}

/**
 * CustomTable component
 */
function CustomTable<T extends RowWithId>(properties: CustomTableProperties<T>) {
  const fullHeight = document.body.clientHeight;
  const {
    header,
    data,
    footerLeft,
    loading,
    height = 0,
    page = 0,
    limit = 0,
    totalPages = 0,
    totalResults = 0,
    pageLimitChange = () => {},
    serialNo,
    rightTopContent,
    actionButton,
    actionDropdown,
    actionButtonList = [],
    actionDropDownList = [],
    sizeChangeButtonRequired,
    filterAllow,
    filter,
    filterFunction = () => {},
    filterClearFunction = () => {},
  } = properties;
  let actionWith = '80px';
  switch (actionButtonList.filter((value) => value.allow).length) {
    case 1:
      actionWith = '80px';
      break;
    case 2:
      actionWith = '115px';
      break;
    case 3:
      actionWith = '165px';
      break;
    case 4:
      actionWith = '200px';
      break;

    default:
      break;
  }
  const [tableSize, setTableSize] = useState<'small' | 'medium' | 'large'>('small');

  /**
   * Function to handle table size change
   */
  const handleChangeTableSize = () => {
    if (tableSize === 'small') {
      setTableSize('medium');
    } else if (tableSize === 'medium') {
      setTableSize('large');
    } else if (tableSize === 'large') {
      setTableSize('small');
    }
  };

  /**
   * Change the page function
   * @param pageNo
   * @param limitCount
   */
  const changePage = (changeDate: ApiRequest) => {
    pageLimitChange(changeDate);
  };
  /**
   * Change sort function
   * @param pageNo
   * @param limitCount
   */
  const sortFunction = (changeDate: string) => {
    pageLimitChange({
      page: DefaultApiParameters.page,
      limit,
      sortField: changeDate,
      sortBy: filter?.sortField === changeDate && filter?.sortBy === 'asc' ? 'desc' : 'asc',
    });
  };

  // Create a style object with CSS variables for each header
  const tableStyle: React.CSSProperties = header.reduce((styleObject, eachHeader) => {
    const width = eachHeader.columnWidth || 140;
    const key = eachHeader.accessorKey.toString();
    return {
      ...styleObject,
      // [`--header-${key}-size`]: width,
      [`--column-${key}-size`]: width,
    };
  }, {});

  return (
    <div className="custom-table-wrap">
      <TopHeaderButtons
        filterFunction={filterFunction}
        filterClearFunction={filterClearFunction}
        sizeChangeButtonRequired={sizeChangeButtonRequired}
        sizeChangeFunction={handleChangeTableSize}
        filterAllow={filterAllow}
        rightTopContent={rightTopContent}
      />
      <div className="custom-table">
        <table className={`table-container ${tableSize}`} style={tableStyle}>
          <thead className="table-header-wrap">
            <tr className={`table-header-container ${!actionButton && 'no-action'}`}>
              {serialNo && (
                <th className="table-header serial">
                  <p>S.No</p>
                </th>
              )}
              {header.map((col) => (
                <th
                  className="table-header"
                  key={col.accessorKey as string}
                  style={{
                    minWidth: `max(calc(var(--column-${col.accessorKey.toString()}-size) * 1px), 40px)`,
                    width: `calc(var(--column-${col.accessorKey.toString()}-size) * 1px)`,
                    flex: `var(--column-${col.accessorKey.toString()}-size) 0 auto`,
                  }}>
                  <p>{col.customLabel ? col.customLabel : col.label}</p>
                  {col.sortAllow && (
                    <div
                      onClick={() => sortFunction(col.accessorKey as string)}
                      role="presentation">
                      <SortIcon />
                    </div>
                  )}
                </th>
              ))}
              {actionButton && (
                <th className="table-header action" style={{ width: actionWith }}>
                  <p>Action</p>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="table-body" style={{ height: `${fullHeight - height}px` }}>
            {loading && (
              <tr className="table-loading">
                <td>
                  <BatteryLoader />
                </td>
              </tr>
            )}
            {!loading && data.length === 0 && (
              <tr className="table-loading">
                <td>
                  <div className="table-no-data-wrap">
                    <img src={NoDataFound} alt="" className="no-data-found-image" />
                    <div className="no-data-found-details">
                      <h3>🔋 Oops! Nothing here yet 🔋</h3>
                      <p>
                        It looks like there&apos;s no data to show right now. Don&apos;t worry—it
                        could just mean things are charging up behind the scenes!
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            )}

            {!loading &&
              data.length > 0 &&
              data.map((row, index) => (
                <tr
                  className="table-row"
                  key={row.id}
                  style={
                    {
                      '--header-length': actionButton ? header.length + 2 : header.length || 1,
                    } as React.CSSProperties
                  }>
                  {serialNo && <td className="table-row-data serial">{index + 1}</td>}
                  {header.map((col) => (
                    <td
                      className="table-row-data"
                      key={col.accessorKey as string}
                      style={{
                        minWidth: `max(calc(var(--column-${col.accessorKey.toString()}-size) * 1px), 40px)`,
                        width: `calc(var(--column-${col.accessorKey.toString()}-size) * 1px)`,
                        flex: `var(--column-${col.accessorKey.toString()}-size) 0 auto`,
                      }}>
                      {col.customData ? (
                        col.customData(row, index)
                      ) : (
                        // Use type assertion here to indicate that row[col.accessorKey] is valid for rendering
                        <p className="table-row-each-data">
                          {row[col.accessorKey] as RowDataTypes as React.ReactNode}
                        </p>
                      )}
                    </td>
                  ))}
                  {actionButton && (
                    <td className="table-row-data action">
                      {actionDropdown ? (
                        <div className="action-button">
                          <DropDownMenu
                            icon={<MoreIcon />}
                            list={actionDropDownList.map((items: any) => ({
                              ...items,
                              id: row.id,
                            }))}
                          />
                        </div>
                      ) : (
                        <div className="action-button-group">
                          {actionButtonList.map(
                            (action: any) =>
                              action.allow && (
                                <div
                                  className={`action-button-item ${action.color}`}
                                  onClick={(event: React.MouseEvent) =>
                                    action.function(row.id, event)
                                  }
                                  role="presentation">
                                  {action.icon}
                                </div>
                              ),
                          )}
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="custom-table-footer">
        <div className="footer-left">{footerLeft}</div>
        <div className="footer-right">
          <Pagination
            active={page}
            setActive={changePage}
            size={totalPages}
            limit={limit}
            totalResults={totalResults}
          />
        </div>
      </div>
    </div>
  );
}

CustomTable.defaultProps = {
  footerLeft: null,
  rightTopContent: null,
  serialNo: false,
  loading: false,
  actionButton: false,
  actionDropdown: false,
  sizeChangeButtonRequired: false,
  filterAllow: true,
};

export default CustomTable;
