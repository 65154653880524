import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import loginBanner from '../../assets/images/login/login-banner.png';
// import sample from '../../assets/videos/background.mp4';

import { ReactComponent as LoginBanner } from '../../assets/icons/login-banner.svg';
import { useAppDispatch } from '../../redux/hooks';
import { setNotificationMessage } from '../../redux/slices/NotificationManagementSlice';
import MyButton from '../../shared-components/button/Button';
import CustomInput from '../../shared-components/custom-input/CustomInput';
import CustomPhoneInput from '../../shared-components/custom-input/CustomPhoneInput';
import RadioButton from '../../shared-components/radio-button/RadioButton';
import { setCookie } from '../../shared-functions/Cookies';
import { setLocalStorageItem } from '../../shared-functions/LocalStorage';
import {
  emailValidation,
  passwordValidation,
  phoneNumberValidation,
} from '../../shared-functions/Validation';
import authApi from '../../utils/api/auth';

/**
 * Login page function component
 */
function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [credentials, setCredentials] = useState<{
    emailOrPhoneNumber: string;
    password: string;
    type: string | number;
    countryCode: string;
  }>({
    emailOrPhoneNumber: '',
    password: '',
    type: 'email',
    countryCode: '1',
  });
  const [submitOpen, setSubmitOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  /**
   * Input field change function
   * @param name
   * @param changeValue
   */
  const handleCredentialChange = (name: string, changeValue: string | number) => {
    if (name === 'type') {
      setCredentials({
        ...credentials,
        [name]: changeValue,
        emailOrPhoneNumber: '',
      });
    } else {
      setCredentials({
        ...credentials,
        [name]: changeValue,
      });
    }
  };

  /**
   * handle submit function
   */
  const submitFunction = () => {
    setSubmitOpen(true);
    if (
      credentials.countryCode !== '' &&
      passwordValidation(credentials.password) &&
      (emailValidation(credentials.emailOrPhoneNumber) ||
        phoneNumberValidation(credentials.emailOrPhoneNumber))
    ) {
      setDisabled(true);
      const request: {
        password: string;
        emailOrPhoneNumber: string;
        countryCode?: string;
      } = {
        password: credentials.password,
        emailOrPhoneNumber: credentials.emailOrPhoneNumber,
      };
      if (phoneNumberValidation(credentials.emailOrPhoneNumber)) {
        request.countryCode = credentials.countryCode;
      }
      authApi
        .login(request)
        .then((response) => {
          const responseData = response?.data;

          // Store access token inside cookies
          setCookie(
            'ev-access-token',
            responseData?.access?.token,
            null,
            responseData?.access?.expires,
          );

          // Store refresh token inside cookies
          setCookie(
            'ev-refresh-token',
            responseData?.refresh?.token,
            null,
            responseData?.refresh?.expires,
          );

          const userVerification = {
            isEmailVerified: responseData?.isEmailVerified,
            isPhoneNumberVerified: responseData?.isPhoneNumberVerified,
          };

          // Set verification in local storage to use in email verification page
          setLocalStorageItem('userVerification', userVerification);
          setDisabled(false);

          // navigate('../ev-admin/dashboard');
          navigate('../ev-admin/account');
        })
        .catch((error) => {
          setDisabled(false);
          dispatch(
            setNotificationMessage({
              message: error?.response?.data?.message,
              status: true,
              type: 'error',
              code: error?.response?.data?.code,
            }),
          );
        });
    }
  };

  return (
    <div className="login-wrap">
      {/* <video className='videoTag' autoPlay loop muted>
        <source src={sample} type='video/mp4' />
      </video> */}
      <div className="login-banner-container">
        {/* <img src={loginBanner} alt="banner" className="login-banner" /> */}
        <LoginBanner />
      </div>
      <div className="login-form-container">
        <div className="login-form-with-border">
          <div className="login-form">
            <h1>Login</h1>
            <RadioButton
              name="type"
              value={credentials.type}
              list={[
                {
                  label: 'Email',
                  value: 'email',
                },
                {
                  label: 'Phone Number',
                  value: 'phone',
                },
              ]}
              onChangeValue={handleCredentialChange}
            />
            {credentials.type === 'email' ? (
              <CustomInput
                inputLabel="Email"
                type="email"
                placeholder="Enter the email"
                name="emailOrPhoneNumber"
                value={credentials.emailOrPhoneNumber}
                onChangeValue={handleCredentialChange}
                error={submitOpen && !emailValidation(credentials.emailOrPhoneNumber)}
                errorMessage="Email is Invalid"
              />
            ) : (
              <CustomPhoneInput
                inputLabel="Phone Number"
                type="number"
                placeholder="Enter the phone number"
                name="emailOrPhoneNumber"
                value={credentials.emailOrPhoneNumber}
                value1={credentials.countryCode}
                onChangeValue={handleCredentialChange}
                error={submitOpen && !phoneNumberValidation(credentials.emailOrPhoneNumber)}
                errorMessage="Phone number is Invalid"
              />
            )}

            <div className="password-container">
              <CustomInput
                inputLabel="Password"
                type="password"
                placeholder="Enter password"
                name="password"
                value={credentials.password}
                onChangeValue={handleCredentialChange}
                error={submitOpen && !passwordValidation(credentials.password)}
                errorMessage="Password is Invalid"
              />
              <div className="forgot-password">
                <span role="presentation" onClick={() => navigate('/forgot-password')}>
                  Forgot Password?
                </span>
              </div>
            </div>
            <MyButton
              label="Submit"
              buttonType="submit"
              onClickFunc={submitFunction}
              disabled={disabled}
            />
          </div>
          {/* <div className="gradient-border">
            <div className="white-bg-color" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
