// import SCSS file
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from './contexts/theme-context/ThemeProvider';
import Router from './pages/router';

/**
 * Main body page
 */
function App() {
  // const theme: string = 'light';

  return (
    <ThemeProvider>
      <div className="app">
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
