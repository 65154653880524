import React, { useEffect, useState } from 'react';

import { ReactComponent as EditPencil } from '../../../assets/icons/edit-pencil.svg';
import { ReactComponent as Export } from '../../../assets/icons/export.svg';
import { ReactComponent as MultiUsers } from '../../../assets/icons/multi-users.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import { ApiRequest } from '../../../interface/common.interface';
import { FilterRoleInterFace, RequestRoleInterFace, RoleInterFace } from '../../../interface/role-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchAllRoles, setRoleFilterData } from '../../../redux/slices/RolesManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import SuccessNotification from '../../../shared-components/success-notification/SuccessNotificaion';

import AddRole from './add-role';
import FilterRole from './filter-role';

interface ModalState {
  status: boolean;
  title: string;
  header: boolean;
  content: JSX.Element;
  rightCorner: string;
  notification: boolean,
}

/**
 * User Management page function component
 */
function RolesList() {
  const dispatch = useAppDispatch();
  const { roles, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.roles,
  );
  const column = [
    {
      label: 'Date',
      accessorKey: 'createdAt' as keyof RoleInterFace,
      sortAllow: true,
    },
    {
      label: 'Role Name',
      accessorKey: 'name' as keyof RoleInterFace,
      sortAllow: true,
    },
    {
      label: 'Responsibility',
      accessorKey: 'responsibility' as keyof RoleInterFace,
      sortAllow: true,
    },
  ];
  const [modalView, setModalView] = useState<ModalState>({
    status: false,
    title: '',
    header: true,
    content: <div />,
    rightCorner: '',
    notification: false,
  });

  /**
   * Fetch Users list function
   */
  const fetchData = (data: ApiRequest) => {
    if (!loading) {
      dispatch(fetchAllRoles(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: true,
      content: <div />,
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Completed the update task
   * @param message
   */
  const completedTask = (message: string) => {
    fetchData({
      limit: DefaultApiParameters.limit,
      page: DefaultApiParameters.page,
    });
    setModalView({
      status: true,
      title: '',
      header: false,
      content: (
        <SuccessNotification
          title={message}
          description=""
          notificationCloseTimer={3000}
          onNotificationClose={closeModal}
        />
      ),
      rightCorner: '',
      notification: true,
    });
  };

  /**
   * User details view function
   */
  const viewFunction = (currentData: String) => {
    const roleDetail: RoleInterFace | undefined = roles.find(
      (value: RoleInterFace) => value.id === currentData,
    );
    setModalView({
      status: true,
      title: 'Edit Role',
      header: true,
      content: (
        <AddRole closeModal={closeModal} roleDetail={roleDetail} completedTask={completedTask} />
      ),
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Handle submit filter function
   * @param value
   */
  const handleSubmitFilter = (filterData: FilterRoleInterFace) => {
    dispatch(setRoleFilterData(filterData));
    fetchData({
      ...filterData,
      page: DefaultApiParameters.page,
      limit,
    });
    setModalView((previous) => ({
      ...previous,
      status: false,
    }));
  };
  /**
   * Handle clear filter data function
   * @param value
   */
  const handleClearFilter = () => {
    dispatch(setRoleFilterData({}));
    fetchData({
      page: DefaultApiParameters.page,
      limit,
    });
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: 'Roles Filter',
      header: false,
      content: <FilterRole closeModal={closeModal} handleSubmitFilter={handleSubmitFilter} oldValue={filter} />,
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: RequestRoleInterFace) => {
    if (value.sortField) {
      dispatch(
        setRoleFilterData({
          ...filter,
          sortField: value.sortField,
          sortBy: value.sortBy,
        }),
      );
    }
    fetchData({ ...filter, ...value });
  };

  useEffect(() => {
    if (roles.length === 0) {
      fetchData({
        ...filter,
        limit,
        page,
      });
    }
  }, []);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
        notification={modalView.notification}
      />
      <CustomTable
        header={column}
        data={roles}
        serialNo
        actionButton
        height={432}
        loading={loading}
        page={page}
        limit={limit}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        sizeChangeButtonRequired
        filter={filter}
        filterClearFunction={handleClearFilter}
        filterFunction={filterFunction}
        actionButtonList={[
          {
            icon: <EditPencil />,
            function: viewFunction,
            color: 'view-color',
            allow: true,
          },
        ]}
        footerLeft={
          <div className="table-bottom-button-list">
            {false && (
              <div className="table-button-item">
                <MyButton
                  label="Export Data"
                  buttonType="back"
                  onClickFunc={() => {}}
                  svgIcon={<Export />}
                />
              </div>
            )}
            <div className="table-button-item">
              <div className="user-count">
                <div className="icon">
                  <MultiUsers />
                </div>
                <div className="value">{totalResults} Users</div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default RolesList;
