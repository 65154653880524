import React, { useState, useEffect, ReactNode, useMemo } from 'react';

import ThemeContext, { initialThemeState } from './ThemeContext';

interface ThemeProviderProperties {
  children: ReactNode;
}

/**
 * Theme Provider function component
 */
export function ThemeProvider({ children }: ThemeProviderProperties) {
  const [theme, setTheme] = useState<string>(initialThemeState.theme);

  const localStorage = window?.localStorage;

  useEffect(() => {
    localStorage.setItem('globalTheme', theme);
  }, [theme]);

  // Use useMemo to memoize the context value
  const value = useMemo(() => ({ theme, setTheme }), [theme]);

  return (
    <ThemeContext.Provider value={value}>
      <div className={`theme--${theme}`}>{children}</div>
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;
