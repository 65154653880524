import React, { useEffect, useState } from 'react';

import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as EditPencilCard } from '../../assets/icons/edit-pencil.svg';
import { ReactComponent as Export } from '../../assets/icons/export.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eye-2-icon.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as DotCircle } from '../../assets/icons/tables/dot-circle.svg';
import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  AccountFilterInterFace,
  AccountsInterFace,
  RequestAccountInterface,
} from '../../interface/account-management.interface';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { fetchAllAccounts, setAccountFilterData } from '../../redux/slices/AccountManagementSlice';
import { setNotificationMessage } from '../../redux/slices/NotificationManagementSlice';
import MyButton from '../../shared-components/button/Button';
import CustomTable from '../../shared-components/custom-table/CustomTable';
import ConfirmBox from '../../shared-components/modal-box/ConfirmBox';
import ModalBox from '../../shared-components/modal-box/ModalBox';
import SuccessNotification from '../../shared-components/success-notification/SuccessNotificaion';
import accountApi from '../../utils/api/account';

import AddAccount from './components/add-account';
import DeleteAccount from './components/delete-account';
import FilterAccount from './components/filter-account';
import ViewAccount from './components/view-account';

interface ModalInterface {
  title: string;
  header: boolean;
  content: JSX.Element;
  rightCorner: string;
  notification: boolean;
}

/**
 * Account Management function Component
 */
function AccountManagement() {
  const dispatch = useAppDispatch();
  const { accounts, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.accounts,
  );
  const column = [
    {
      label: 'Date',
      accessorKey: 'createdAt' as keyof AccountsInterFace,
      sortAllow: true,
    },
    {
      label: 'Account Id',
      accessorKey: 'accountCustomId' as keyof AccountsInterFace,
      sortAllow: true,
    },
    {
      label: 'Verification Status',
      accessorKey: 'status' as keyof AccountsInterFace,
      sortAllow: true,
      /**
       * Data custom function
       * @param currentRow
       */
      customData: (currentRow: AccountsInterFace) => {
        let output = <div />;

        switch (currentRow.status) {
          case 'pending':
            output = (
              <div className="tag-warning-label">
                <DotCircle /> <span>Unverified</span>
              </div>
            );
            break;
          case 'approved':
            output = (
              <div className="tag-success-label">
                <DotCircle /> <span>Verified</span>
              </div>
            );
            break;
          case 'rejected':
            output = (
              <div className="tag-danger-label">
                <DotCircle /> <span>Rejected</span>
              </div>
            );
            break;

          default:
            break;
        }

        return output;
      },
    },
    {
      label: 'Account Type',
      accessorKey: 'accountType' as keyof AccountsInterFace,
      sortAllow: true,
    },
  ];

  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [account, setAccount] = useState<AccountsInterFace>({
    _id: '',
    id: '',
    name: '',
    email: '',
    date: '',
    phone: '',
    tenantId: '',
    isAccountVerified: false,
    documents: [],
    currency: '',
  });
  const [modalView, setModalView] = useState<ModalInterface>({
    title: '',
    header: false,
    content: <div />,
    rightCorner: '',
    notification: false,
  });

  /**
   * Fetch the account details in redus
   */
  const fetchData = (data: RequestAccountInterface) => {
    if (!loading) {
      dispatch(fetchAllAccounts(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = (message: string) => {
    if (message) {
      setOpenStatus(true);
      setModalView({
        title: '',
        header: false,
        content: (
          <SuccessNotification
            title={message}
            description=""
            notificationCloseTimer={3000}
            onNotificationClose={() => {
              fetchData({
                limit,
                page: DefaultApiParameters.page,
              });
              setOpenStatus(false);
            }}
          />
        ),
        rightCorner: '',
        notification: true,
      });
    } else {
      setOpenConfirm(false);
      setOpenStatus(false);
    }
  };

  /**
   * Add account function
   */
  const addAccount = () => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: true,
      content: <AddAccount closeModal={closeModal} />,
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Edit account function
   */
  const editAccount = (currentData: String) => {
    const accountDetails = accounts.find((value: AccountsInterFace) => value.id === currentData);
    if (accountDetails && Object.keys(accountDetails).length > 0) {
      setOpenStatus(true);
      setModalView({
        title: 'Edit Account',
        header: true,
        content: <AddAccount closeModal={closeModal} currentData={accountDetails} />,
        rightCorner: '',
        notification: false,
      });
    }
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageAndLimitChange = (value: RequestAccountInterface) => {
    if (value.sortField) {
      dispatch(
        setAccountFilterData({
          ...filter,
          sortField: value.sortField,
          sortBy: value.sortBy,
        }),
      );
    }
    fetchData({ ...filter, ...value });
  };

  /**
   * Handle submit filter function
   * @param value
   */
  const handleSubmitFilter = (filterData: AccountFilterInterFace) => {
    dispatch(setAccountFilterData(filterData));
    fetchData({
      ...filterData,
      page: DefaultApiParameters.page,
      limit,
    });
    setOpenStatus(false);
  };
  /**
   * Handle clear filter data function
   * @param value
   */
  const handleClearFilter = () => {
    dispatch(setAccountFilterData({}));
    fetchData({
      page: DefaultApiParameters.page,
      limit,
    });
  };

  /**
   * Confirm modal box submit function
   */
  const conformSubmit = (data: AccountsInterFace) => {
    setOpenConfirm(true);
    setAccount(data);
  };

  /**
   * Account delete submit function
   */
  const deleteFunction = (currentData: String) => {
    const accountDetails = accounts.find((value: AccountsInterFace) => value.id === currentData);
    if (accountDetails && Object.keys(accountDetails).length > 0) {
      accountApi
        .fetchOneAccounts(accountDetails.tenantId)
        .then((response) => {
          setOpenStatus(true);
          setModalView({
            title: 'Add Account',
            header: false,
            content: (
              <DeleteAccount
                accountDetails={{ ...accountDetails, ...response.data }}
                conformSubmit={conformSubmit}
                closeModal={closeModal}
              />
            ),
            rightCorner: '',
            notification: false,
          });
        })
        .catch((error) => {
          dispatch(
            setNotificationMessage({
              message: error?.response?.data?.message,
              status: true,
              type: 'error',
              code: error?.response?.data?.code,
            }),
          );
        });
    }
  };

  /**
   * Account details view function
   */
  const viewFunction = (currentData: String) => {
    const accountDetails = accounts.find((value: AccountsInterFace) => value.id === currentData);
    if (accountDetails && Object.keys(accountDetails).length > 0) {
      setOpenStatus(true);
      setModalView({
        title: '',
        header: false,
        content: <ViewAccount accountDetails={accountDetails} refersData={closeModal} />,
        rightCorner: '',
        notification: false,
      });
    }
  };

  /**
   * Delete confirm function
   */
  const deleteConfirmFunction = () => {
    accountApi.deleteAccount(account.tenantId).then(() => {
      closeModal('Account Deleted Successfully...');
    });
  };

  /**
   * Account filter view function
   */
  const accountFilterFunction = () => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: false,
      content: (
        <FilterAccount
          closeModal={closeModal}
          oldValue={filter}
          handleSubmitFilter={handleSubmitFilter}
        />
      ),
      rightCorner: '',
      notification: false,
    });
  };

  useEffect(() => {
    if (accounts.length === 0) {
      if (filter)
        fetchData({
          ...filter,
          limit,
          page,
        });
    }
  }, []);
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setOpenStatus(false)}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
        notification={modalView.notification}
      />
      <ConfirmBox
        open={openConfirm}
        title="Are you sure want to verify this account?"
        subTitle="This will allow facility owner account to add facility& devices"
        conformSubmit={deleteConfirmFunction}
        closeModal={() => {
          setOpenConfirm(false);
          setOpenStatus(false);
          setAccount({
            _id: '',
            id: '',
            name: '',
            email: '',
            date: '',
            phone: '',
            tenantId: '',
            isAccountVerified: false,
            currency: '',
          });
        }}
      />
      <div className="container">
        <div className="header-title">
          <h2>Account Management</h2>
        </div>
        <div className="body-container">
          <div className="table-content">
            <CustomTable
              header={column}
              data={accounts}
              limit={limit}
              page={page}
              totalPages={totalPages}
              totalResults={totalResults}
              loading={loading}
              serialNo
              pageLimitChange={pageAndLimitChange}
              actionButton
              height={357}
              filter={filter}
              filterFunction={accountFilterFunction}
              filterClearFunction={handleClearFilter}
              sizeChangeButtonRequired
              actionDropdown
              actionDropDownList={[
                {
                  content: (
                    <div className="flex-row">
                      <div className="text-primary">
                        <EditPencilCard />
                      </div>{' '}
                      Edit
                    </div>
                  ),
                  function: editAccount,
                  allow: true,
                },
                {
                  content: (
                    <div className="flex-row">
                      <div className="text-primary">
                        <EyeIcon />
                      </div>{' '}
                      View
                    </div>
                  ),
                  function: viewFunction,
                  allow: true,
                },
                {
                  content: (
                    <div className="flex-row">
                      <div className="text-danger">
                        <Delete />
                      </div>{' '}
                      Block
                    </div>
                  ),
                  function: deleteFunction,
                  allow: false,
                },
              ]}
              footerLeft={
                <div className="table-bottom-button-list">
                  <div className="table-button-item">
                    <MyButton
                      label="Add Account"
                      buttonType="submit"
                      onClickFunc={addAccount}
                      svgIcon={<Plus />}
                    />
                  </div>
                  {false && (
                    <div className="table-button-item">
                      <MyButton
                        label="Export Data"
                        buttonType="back"
                        onClickFunc={() => {}}
                        svgIcon={<Export />}
                      />
                    </div>
                  )}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountManagement;
