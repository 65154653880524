import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

interface DataPoint {
    month: string;
    revenue: number;
}

const data: DataPoint[] = [
    { month: 'Jan', revenue: 30000 },
    { month: 'Feb', revenue: 32000 },
    { month: 'Mar', revenue: 31000 },
    { month: 'Apr', revenue: 54000 },
    { month: 'May', revenue: 33000 },
    { month: 'Jun', revenue: 34000 },
    { month: 'Jul', revenue: 36000 },
    { month: 'Aug', revenue: 28000 },
    { month: 'Sep', revenue: 7000 },
    { month: 'Oct', revenue: 39000 },
    { month: 'Nov', revenue: 40000 },
    { month: 'Dec', revenue: 42000 },
];

/**
 * Report the Chart
 * @returns 
 */
function ReportGraphChart() {
    const chartData = {
        labels: data.map((d) => d.month),
        datasets: [
            {
                label: 'Revenue',
                data: data.map((d) => d.revenue),
                borderColor: '#0399e0',
                /**
                 * Background color function
                 * @param context 
                 * @returns 
                 */
                backgroundColor: (context: any) => {
                    const { chart } = context;
                    const gradient = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
                    gradient.addColorStop(0, 'rgba(104, 192, 228, 1)');
                    gradient.addColorStop(1, 'rgba(237, 237, 237, 0)');
                    return gradient;
                },
                fill: "origin",  // Fills to the previous dataset
                tension: 0.4,
                borderWidth: 1,
                pointRadius: 0,
                pointHoverRadius: 4,
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,  // Explicitly cast to 'top' to ensure correct type
                align: 'end' as const,
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    boxHeight: 10,
                    borderRadius: 10,
                    padding: 15,
                },
                padding: {
                    right: 500,            // Add 50px padding to the right side of the legend
                },
            },
            tooltip: {
                /**
                 * Background color function
                 * @param context 
                 * @returns 
                 */
                backgroundColor: (context: any) => {
                    if (context?.tooltipItems?.[0]?.dataset) {
                        // Check the dataset label and apply different colors
                        if (context.tooltipItems[0].dataset.label === 'Revenue') {
                            return '#0399e0'; // Orange for Revenue
                        }
                    }
                    return 'rgba(0, 0, 0, 0.8)'; // Default color
                },
                titleColor: '#FFFFFF',
                bodyColor: '#FFFFFF',
                padding: 10,
                borderRadius: 8,
                cornerRadius: 4,
                displayColors: false,
                callbacks: {
                    /**
                     * Title function
                     * @param context 
                     * @returns 
                     */
                    title: (context: any) => {
                        // Set title based on dataset
                        const datasetLabel = context[0]?.dataset?.label;
                        return datasetLabel === 'Revenue' ? 'Revenue' : 'Expenses';
                    },
                    /**
                     * label function
                     * @param context 
                     * @returns 
                     */
                    label: (context: any) => {
                        // Format the label with currency
                        return `${context?.raw.toLocaleString()} (SAR)`;
                    },
                },
            },
        },

        interaction: {
            intersect: false, // Don't require an exact point to show the tooltip
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false,  // Hides the y-axis line
                },

            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,  // Hides the tick labels on the y-axis (revenue and expense values)
                },
                border: {
                    display: false,  // Hides the y-axis line
                },
            },
        },
    };

    return (
        <div style={{ width: "100%" }}>
            <Line
                data={chartData}
                options={options}
                height={200} // Explicit height for the chart
                width={900}
            />
        </div>
    );
};

export default ReportGraphChart;
